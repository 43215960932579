// @flow
import React, { useState } from 'react';
import {Button, Panel} from "react-bootstrap";
import CCIcon from "../../../components/CCIcon";
import {FaSpinner} from 'react-icons/lib/fa'

const PaymentDetails = ({info, submitCardDetails, showPaymentElement, setShowPaymentElement}) => {

    const [updating, setUpdating] = useState(false);

    const submitCardClicked = async () => {
        setUpdating(true);
        await submitCardDetails();
        setUpdating(false);
    }

    return (
        <div className="billing-info">
            <Panel>
                <Panel.Heading style={{fontSize: 18}}>Billing Information</Panel.Heading>
                <Panel.Body>
                    {info.lastFour ? (
                        <div>
                            <p>
                                This account is billed to:&nbsp;<CCIcon brand={info.brand}/>&nbsp; 
                                <b>{info.brand} ending in {info.lastFour}</b>
                            </p>
                            {!showPaymentElement && (
                                <Button 
                                    onClick={() => setShowPaymentElement(true)}
                                    bsStyle="primary" 
                                    bsSize="sm"
                                >
                                    Update Credit Card
                                </Button>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>This account has no billing information</p>
                            {!showPaymentElement && (
                                <Button 
                                    onClick={() => setShowPaymentElement(true)}
                                    bsStyle="primary" 
                                    bsSize="sm"
                                >
                                    Add Credit Card {updating && <FaSpinner className="fa-spin"/>}
                                </Button>
                            )}
                        </div>
                    )}
                    
                    {showPaymentElement && (
                        <div>
                            <div id="payment-element"></div>
                            <div style={{marginTop: '20px', display: 'flex', gap: '10px'}}>
                                <Button 
                                    onClick={submitCardClicked}
                                    bsStyle="primary" 
                                    bsSize="sm"
                                    disabled={updating}
                                >
                                    Submit
                                     {updating && <FaSpinner className="fa-spin" style={{verticalAlign: 'text-top', marginLeft: 5, marginRight: -5}}/>}
                                </Button>
                                <Button 
                                    onClick={() => setShowPaymentElement(false)}
                                    bsSize="sm"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    )}
                </Panel.Body>
            </Panel>
        </div>
    );
};

export default PaymentDetails;