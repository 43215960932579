// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import PaymentDetails from './PaymentDetails'
import { confirmModal } from '../../../modals/ConfirmModal';
import { modal } from '../../../modals/BasicModal';
import CCIcon from '../../../components/CCIcon';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/lib/fa';
import * as subscriptionApi from '../../../api/billing/subscription';
import { useFeatureflow } from 'react-featureflow-client';
import { dispatch } from "../../../redux";
import * as subscriptionOutline from "../../../redux/subscriptionOutline";
import {isAdmin} from '../../../utils/authentication'
import type { Price } from "../../../api/billing/types";
import {Row, Col} from "react-bootstrap";
import AvailableProducts from "./AvailableProducts";
import AvailablePrices from "./AvailablePrices";
import CurrentPlan from "./CurrentPlan";
import BillingHistory from "./BillingHistory";


const priceSort = function (a, b) {
    let keyA = a.meta.order,
        keyB = b.meta.order;
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
};

function Billing(props: {
    email: string,
    subscription: any,
    organisation: any,
}): React.Element<any> {
    const [updating, setUpdating] = useState(false);
    const [prices, setPrices] = useState();
    const [info, setInfo] = useState();
    const [usage, setUsage] = useState();
    const [invoices, setInvoices] = useState();
    const [couponCode, setCouponCode] = useState('');
    const [coupon, setCoupon] = useState();
    const [showPaymentElement, setShowPaymentElement] = useState(false);
    const [paymentElement, setPaymentElement] = useState(null);    
    const [stripePromise, setStripePromise] = useState(null);
    const [stripe, setStripe] = useState(null);
    const [elements, setElements] = useState(null);
    const featureflow = useFeatureflow();
    const subscription = props.subscription;
    useEffect(() => {
        const stripePromise = loadStripe(props.organisation.stripeKey, {
            apiVersion: '2023-10-16',
        });
        setStripePromise(stripePromise);

        Promise.all([
            subscriptionApi.getPrices(),
            subscriptionApi.getBillingInfo(),
            subscriptionApi.getBillingUsage(),
            subscriptionApi.getBillingInvoices(),
        ]).then(([prices, info, usage, history]) => {

            setTimeout(() => {
                setPrices(prices.sort(priceSort));
                setInfo(info);
                setUsage(usage);
                setInvoices(history.items.filter(invoice => invoice.paid));
            }, 1);
        }).catch(console.warn);
    }, []);

    const setupStripe = async () => {
        const stripeInstance = await stripePromise;
        setStripe(stripeInstance);
        const { clientSecret } = await subscriptionApi.createSetupIntent();
        const elementsInstance = stripeInstance.elements({
            clientSecret,
            appearance: {
                theme: 'stripe',
            },
        });
        setElements(elementsInstance);

        // Create and mount the Payment Element
        const element = elementsInstance.create('payment');
        setPaymentElement(element);

        setTimeout(() => {
            element.mount('#payment-element');
        }, 0);
    }

    useEffect( ()  => {
        async function setup() {
            if(showPaymentElement){
                await setupStripe();
            }
        }
        setup();
    }, [showPaymentElement]);

    const submitCardDetails = async () => {
        try {
            if (!stripe || !elements){
                await setupStripe();
            }
            const { error, setupIntent } = await stripe.confirmSetup({
                elements,
                redirect: 'if_required', // Don't redirect, handle response directly
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            email: props.email,
                        }
                    },
                },
            });

            if (error) {
                setShowPaymentElement(false);
                paymentElement?.destroy();
                modal('Error Updating Card',
                    <div style={{textAlign: 'center', height: 250}}>
                        <b>{error.message}</b>
                        <br/> <br/>
                        Please contact <a href="mailto:billing@featureflow.io">billing@featureflow.io</a> if the issue persists.
                    </div>
                ).catch(console.warn);
            } else {
                // Set the new payment method as default
                try {
                    await subscriptionApi.setDefaultPaymentMethod(setupIntent.payment_method);
                } catch (error) {
                    console.error('Error setting default payment method:', error);
                }

                // Show success message
                modal('Success',
                    <div style={{textAlign: 'center', height: 250}}>
                        <div style={{fontSize: 100, color: '#60c3a7'}}>
                            <FaCheckCircle/>
                        </div>
                        <b>Your card has been successfully updated.</b>
                    </div>
                ).catch(console.warn);

                // Clean up payment element and state
                setShowPaymentElement(false);
                paymentElement?.destroy();
                setPaymentElement(null);
                setElements(null);
                setStripe(null);

                // Refresh billing info
                try {
                    const newInfo = await subscriptionApi.getBillingInfo();
                    setInfo(newInfo);
                } catch (error) {
                    console.error('Error refreshing billing info:', error);
                }
            }

        } catch (error) {
            console.error('Setup error:', error);
            setShowPaymentElement(false);
            paymentElement?.destroy();
            setPaymentElement(null);
            setElements(null);
            setStripe(null);
            modal('Error Updating Card',
                <div style={{textAlign: 'center', height: 250}}>
                    <b>An unexpected error occurred</b>
                    <br/> <br/>
                    Please contact <a href="mailto:billing@featureflow.io">billing@featureflow.io</a> if the issue persists.
                </div>
            ).catch(console.warn);
        }
    };

    const cancelSubscription = async () => {
        confirmModal(`Cancel subscription`,
            <div>
                Are you sure you wish to cancel your subscription and move back to the free tier?
            </div>
        ).then(result => {
           doCancelSubscription();
        })
    };
    const doCancelSubscription = async () => {
        const newSub = await subscriptionApi.cancelSubscription();
        dispatch(subscriptionOutline.setSubscription(newSub));
        await modal('Subscription Cancelled',
            <div style={{textAlign: 'center', height: 250}}>
                <div style={{fontSize: 100, color: '#60c3a7'}}>
                    <FaCheckCircle/>
                </div>
                <b>Your subscription cancellation was successful.</b>
            </div>
        );
    }

    const getCoupon = (couponCode: string) => {
        subscriptionApi.getCoupon(couponCode)
            .then(coupon => {
                setCoupon(coupon);
            }).catch(error => {
        });
    };

    const subscribeToPrice = async (price: Price, seats?: number) => {
        if (!info) return;

        try {
            if (!info.lastFour) {
                const confirmed = await confirmModal('Add Payment Method',
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontSize: 60, color: '#60c3a7', marginBottom: '20px'}}>
                            <CCIcon brand="credit-card"/>
                        </div>
                        <b>Please add your card details first before subscribing to {price.productName}.</b>
                    </div>,
                    [
                        {
                            text: 'Add Card',
                            value: true,
                            style: 'primary'
                        },
                        {
                            text: 'Cancel',
                            value: false
                        }
                    ]
                );

                if (!confirmed) {
                    return;
                }

                setShowPaymentElement(true);
                return;
            }
            let subscriptionResult;

            // If they already have a card, show confirmation dialog
            const cardDetails = info;
            const confirmed = await confirmModal(`Subscribe to ${price.productName}?`,
                <div>
                    Do you want to change your subscription to {price.productName}? <br/>
                    { seats &&
                        <>Your card will be charged ${(price.amount / 100 * seats).toFixed(2)}/{price.interval} ({price.currency.toUpperCase()}) for {seats} seats. <br/>
                            Changes take effect immediately and your account is pro-rated to reflect the change.
                        </>
                    }
                    { !seats &&
                        <>Your card will be charged ${(price.amount / 100).toFixed(2)}/{price.interval}({price.currency.toUpperCase()}). <br/></>
                    }
                    <br/>
                    Billing to: <b><CCIcon brand={cardDetails.brand}/> {cardDetails.brand} ending in {cardDetails.lastFour}</b>
                    <br/>
                    {
                        featureflow.evaluate('coupons').isOn() &&
                        <span>
                            If you have a coupon code, enter it here:
                              <input value={couponCode}
                                     onBlur={(event)=>{
                                         getCoupon(event.target.value, couponCode)
                                     }}/>
                        </span>
                    }
                </div>
            );

            if (confirmed) {
                subscriptionResult = await subscriptionApi.subscribe(price.id, seats);
            }

            // Handle successful subscription
            if (subscriptionResult) {
                dispatch(subscriptionOutline.setSubscription(subscriptionResult));
                await confirmModal('Success',
                    <div style={{textAlign: 'center', height: 250}}>
                        <div style={{fontSize: 100, color: '#60c3a7'}}>
                            <FaCheckCircle/>
                        </div>
                        <b>Your subscription to {price.productName} was successful.</b>
                    </div>,
                    [
                        {
                            text: 'Ok',
                            value: true,
                            style: 'primary'
                        }
                    ]
                );
            }
        } catch (error) {
            console.error('Subscription error:', error);
            modal('Warning',
                <div style={{textAlign: 'center', height: 250}}>
                    <div style={{fontSize: 100, color: '#60c3a7'}}>
                        <FaExclamationTriangle/>
                    </div>
                    <b>Sorry, we were unable to subscribe you to the {price.productName} plan. Please contact us using the chat below and we will be happy to set you up.</b>
                    <br/>
                    <small>{error?.message}</small>
                </div>
            ).catch(console.warn);
        }
    };

    if (!isAdmin()) {
        return (
            <div>
                <h2>Billing</h2>
                <div style={{textAlign: 'center', marginTop: 100, marginBottom: 100}}>
                    <p>Only organisation admins may manage billing. Please contact your administrator to update billing
                        details. </p>
                    <p>For details on pricing plans, please see <a href={"https://www.featureflow.io/pricing/"}
                                                                   target={"_blank"}>here</a></p>
                </div>
            </div>
        )
    }

    if (info && prices && usage && invoices && !updating) {
        return (
            <>
                <Row>
                    <Col xs={12}>
                        <h2>Billing</h2>
                        <PaymentDetails 
                            info={info} 
                            showPaymentElement={showPaymentElement}
                            setShowPaymentElement={setShowPaymentElement}
                            submitCardDetails={submitCardDetails}
                        />
                    </Col>
                </Row>
                {featureflow.evaluate("pricing-plans").is("2") &&
                    <AvailableProducts
                        currentPriceId={subscription.price.id}
                        currentProductId={subscription.price.id}
                        subscribeToPrice={subscribeToPrice}
                        cancelSubscription={cancelSubscription}
                        paidSeats={subscription.quantity}
                        usedSeats={ usage.seats }
                    />
                }
                {!featureflow.evaluate("pricing-plans").is("2") &&
                    <AvailablePrices
                        prices={prices}
                        currentPriceId={subscription.price.id}
                        subscribeToPrice={subscribeToPrice}
                        cancelSubscription={cancelSubscription}
                    />
                }
                <Row>
                    <CurrentPlan subscription={subscription} usage={usage}/>
                </Row>
                <Row>
                    <BillingHistory invoices={invoices}/>
                </Row>
            </>
        )
    } else {
        return (
            <div>
                <h2>Billing</h2>
                <div style={{textAlign: 'center', marginTop: 100, marginBottom: 100}}>
                    <p>{updating ? 'Updating billing information...' : 'Retrieving billing information...'}</p>
                </div>
            </div>
        )
    }
    
    
    
}

function mapStateToProps(state) {
    return {
        email: state.principalUserOutline.email,
        subscription: state.subscriptionOutline,
        organisation: state.organisation
    }
}

export default connect(mapStateToProps)(Billing);