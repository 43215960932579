// @flow
import React from 'react';
import createRoutes from './routes/index';
import { Router, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import access from 'safe-access';

import console from './utils/debug';

import featureflow from './config/featureflow';

import { goToRoute } from './utils/routing';

import { dispatch } from './redux';

import * as modalController from './redux/modalController';

import * as projectsStore from './redux/projects';
import * as principalUserOutline from './redux/principalUserOutline';
import * as organisationStore from './redux/organisation';
import * as subscriptionOutline from './redux/subscriptionOutline';

import * as outlineApi from './api/outline';

import OnboardingCarousel from './components/OnboardingCarousel';

import { modal } from './modals/BasicModal';
import LoadingOverlay from "./components/LoadingOverlay";
import UpdatesCarousel from "./components/UpdatesCarousel";

const routes = createRoutes();

function mapStateToProps(state){
  return {
    isAuthenticated: !!state.authentication.token,
    principalUserOutline: state.principalUserOutline
  }
}


class FeatureflowRouter extends React.Component{
  state = {
    showLoading: false
  };
  constructor(props){
    super(props);
    if (this.props.isAuthenticated){
      if (module.hot && props.principalUserOutline){
        console.log('HOT RELOAD');
      }
      else {
        this.loadDataforAuthenticatedUser();
        this.state = {
          showLoading: true
        };
      }
    }
  }

  componentWillReceiveProps(props){
    if (!this.props.isAuthenticated && props.isAuthenticated && !this.state.showLoading){
      this.setState({
        showLoading: true
      });
      this.loadDataforAuthenticatedUser();
      goToRoute('/');
    }
  }

  loadDataforAuthenticatedUser(){
    Promise.all([
      outlineApi.get(),
    ]).then(([_outline])=>{
      let { principalUser, organisation, projects, subscription } = _outline;

        var myDate = new Date(principalUser.activatedDate);
        var unixdate = (myDate.getTime()/1000).toFixed(0);
        //var unixdate = Date.parse(principalUser.activatedDate).getTime()/1000;

        window.Intercom("boot", {
          app_id: "bwnhi8hi",
          name: `${principalUser.firstName} ${principalUser.lastName}`, // Full name
          created_at: unixdate, // Signup date as a Unix timestamp
          email: principalUser.email, // Email address
          user_id: principalUser.login,
          user_hash: principalUser.userHash,
          organisationId: organisation.id,
          organisationName: organisation.name,
          activatedDate: principalUser.activatedDate,
          domain: organisation.domain
      });
      const analyticsUser = {
        id: principalUser.id,
        attributes: {
          fullName: `${principalUser.firstName} ${principalUser.lastName}`,
          email: principalUser.email,
          roles: principalUser.authorities,
          langKey: principalUser.langKey,
          organisationId: organisation.id,
          domain: organisation.domain,
          organisationName: organisation.name,
          activatedDate: principalUser.activatedDate,
          created_at: unixdate

        }
      };
      featureflow.updateUser(analyticsUser, (error)=>{

        //segment identify
        analytics.identify(analyticsUser.id, {
          ...analyticsUser.attributes,
          ...featureflow.getFeatures()});

        dispatch(organisationStore.setOrganisation(organisation));
        dispatch(subscriptionOutline.setSubscription(subscription));
        dispatch(projectsStore.setProjects(projects));
        dispatch(principalUserOutline.setPrincipalUser(principalUser));

        //FEATUREFLOW ONBOARDING MESSAGE
        if (featureflow.evaluate('welcome-message').isOn()){
          const readDate = access(principalUser, 'settings.introReadDate');
          const hasRead = !!readDate;


          if (!readDate){
            modal(
              undefined,
              <OnboardingCarousel onClose={()=>{
                  dispatch(modalController.close())
                  dispatch(principalUserOutline.markIntroRead(principalUser.id));
                }
              }/>,
              false,
              true,
              {
                bsSize: 'large'
              }
            );
          }
        }

        setTimeout(()=>{this.setState({
            showLoading: false
          })
        }, 0);
      })
    }).catch(err=>{
        this.setState({
          showLoading: false
        })

        goToRoute('/logout');
    })
  }


  render(): React.Element<any>{
    if (this.state.showLoading){
      return <LoadingOverlay visible/>
    }
    return (
      <Router history={browserHistory}>
        {routes}
      </Router>
    )
  }
}

export default connect(mapStateToProps)(FeatureflowRouter);