// @flow
import React from 'react';
import {Col, OverlayTrigger, ProgressBar, Row, Table, Tooltip} from "react-bootstrap";
import {useFeatureflow} from "react-featureflow-client";
import type {Subscription} from "../../../api/billing/types";

const CurrentPlan = (props = {subscription: Subscription, usage: BillingUsage}) => {
    const {subscription, usage} = props;
    const featureflow = useFeatureflow();
    const {price, inTrial, daysUntilEndOfTrial, status} = subscription;
    const showImpressionLimits = featureflow.evaluate('impressions-limits').isOn();


    const renderProgressBar = (usage: number, limit: number): React.Element<ProgressBar> => {
        let style;
        if (usage >= limit) {
            style = 'danger';
        } else if (100 / limit * usage >= 90) {
            style = 'warning';
        }
        return (
            <ProgressBar min={0}
                         max={limit}
                         now={usage}
                         bsStyle={style}
            />
        );
    }


    const numberWithCommas = (x) => {
        if (!x && x !== 0) {
            return "Unlimited";
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function plural(number, word) {
        return `${number === 1 ? word : numberWithCommas(number) + ' ' + word + 's'}`
    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    <h1>Current Plan</h1>
                    <h3>{price.productName}</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                <h4>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id={`tooltip-bill-estimate`}>
                                Current estimated bill amount including discounts.
                            </Tooltip>
                        }
                    >
                        <span>
                        {
                            !subscription.status === 'inactive' &&
                            <>${(subscription.total / 100).toFixed(2)} {price.currency}/{price.interval}</>
                        }
                            {
                                subscription.price.unitLabel === 'seat' &&
                                <>
                                    ({subscription.quantity} {subscription.price.unitLabel}{subscription.quantity > 1 && `s`}
                                    &nbsp;at ${(subscription.price.amount / 100).toFixed(2)} per {subscription.price.unitLabel}
                                    &nbsp;per {plural(subscription.price.intervalCount || 1, subscription.price.interval)}
                                    {
                                        subscription.percentOff &&
                                        <span> with {subscription.percentOff}% discount</span>
                                    }
                                    {
                                        subscription.amountOff &&
                                        <span> with ${subscription.amountOff / 100} {price.currency} discount</span>
                                    }
                                    )
                                </>
                            }
                            {
                                subscription.quantity === 1 &&

                                <span>
                                    {
                                        subscription.percentOff &&
                                        <span> (with {subscription.percentOff}% discount)</span>
                                    }
                                    {
                                        subscription.amountOff &&
                                        <span> (with ${subscription.amountOff / 100} {price.currency} discount)</span>
                                    }
                            </span>
                            }
                        </span>
                    </OverlayTrigger>
                </h4>

                {
                    inTrial &&
                    <div>
                        <div>
                            You have {daysUntilEndOfTrial} {daysUntilEndOfTrial === 1 ? 'day' : 'days'} left in your
                            trial.
                        </div>
                        <div>
                            Once your trial has expired you will not be able to make changes to features but they will
                            continue to evaluate.
                        </div>
                    </div>

                }
                {
                    status === 'inactive' &&
                    <div>
                        <div>
                            <b>Your trial has expired.</b> You will not be able to make changes to features but don't
                            worry,
                            they will continue to evaluate!
                        </div>
                        <div>
                            Please sign up to one of our plans above.
                        </div>
                    </div>
                }

                <h3>Limits</h3>
                <Table bordered condensed className="right-most-column-full-width" style={{maxWidth: 500}}>
                    <tbody>
                    <tr>
                        <th>Seats</th>
                        <td>{numberWithCommas(subscription.limits.maxOrganisationUsers)}</td>
                    </tr>
                    <tr>
                        <th>Audit Log</th>
                        <td>{subscription.limits.auditLog ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <th>A/B Testing</th>
                        <td>{subscription.limits.variantTesting ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <th>Monthly Unique Users</th>
                        <td>{numberWithCommas(subscription.limits.maxApplicationUniqueUsers)}</td>
                    </tr>
                    {
                        showImpressionLimits &&
                        <tr>
                            <th>Monthly Impressions</th>
                            <td>{numberWithCommas(subscription.limits.maxImpressions)}</td>
                        </tr>
                    }
                    </tbody>
                </Table>
                <h3>Usages</h3>
                {usage &&
                    <Table bordered condensed className="right-most-column-full-width" style={{maxWidth: 500}}>
                        <tbody>
                        <tr>
                            <th>Seats used</th>
                            <td style={{textAlign: 'center'}}>
                                {numberWithCommas(usage.seats)} out
                                of {numberWithCommas(subscription.limits.maxOrganisationUsers)}<br/>
                                {renderProgressBar(usage.seats, subscription.limits.maxOrganisationUsers)}
                            </td>
                        </tr>
                        <tr>
                            <th>Projects used</th>
                            <td style={{textAlign: 'center'}}>
                                {numberWithCommas(usage.projectsUsed)} out
                                of {numberWithCommas(subscription.limits.maxProjects)}<br/>
                                {renderProgressBar(usage.projectsUsed, subscription.limits.maxProjects)}
                            </td>
                        </tr>
                        <tr>
                            <th>Environments used</th>
                            <td style={{textAlign: 'center'}}>
                                {numberWithCommas(usage.environmentsUsed)} out
                                of {numberWithCommas(subscription.limits.maxEnvironments)}<br/>
                                {renderProgressBar(usage.environmentsUsed, subscription.limits.maxEnvironments)}
                            </td>
                        </tr>
                        <tr>
                            <th>Features used</th>
                            <td style={{textAlign: 'center'}}>
                                {numberWithCommas(usage.featuresUsed)} out
                                of {numberWithCommas(subscription.limits.maxFeatures)} <br/>
                                {renderProgressBar(usage.featuresUsed, subscription.limits.maxFeatures)}
                            </td>
                        </tr>
                        <tr>
                            <th>Monthly Unique users used</th>
                            <td style={{textAlign: 'center'}}>
                                {numberWithCommas(usage.applicationUniqueUsersUsed)} out
                                of {numberWithCommas(subscription.limits.maxApplicationUniqueUsers)} <br/>
                                {renderProgressBar(usage.applicationUniqueUsersUsed, subscription.limits.maxApplicationUniqueUsers)}
                            </td>
                        </tr>
                        {showImpressionLimits &&
                            <tr>
                                <th>Monthly Impressions used</th>
                                <td style={{textAlign: 'center'}}>
                                    {numberWithCommas(usage.impressionsUsed)} out
                                    of {numberWithCommas(subscription.limits.maxImpressions)}
                                    <br/>
                                    {renderProgressBar(usage.impressionsUsed, subscription.limits.maxImpressions)}
                                </td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                }
                </Col>
            </Row>
        </>
    )
}
export default CurrentPlan;
