// @flow
 import React, { useEffect, useState } from 'react';
import access from 'safe-access';
import {
    Breadcrumb,
    Button,
    Col, DropdownButton,
    Label, MenuItem,
    OverlayTrigger,
    Row,
    Tab,
    Tabs,
    Tooltip
} from 'react-bootstrap';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import CopyToClipboard from "react-copy-to-clipboard";
import Alert from "react-s-alert";
import {FaCopy, FaEdit} from "react-icons/lib/fa";
import {LinkContainer} from "react-router-bootstrap";
import type {Project} from '../../api/project'
import type {Environment} from '../../api/environment'


import FeatureStatusIndicator from "../../components/FeatureStatusIndicator";
import FeatureControlDetails from "./FeatureControlDetails";
import Maintainer from "./Maintainer";
import BookmarkFeature from "../../components/BookmarkFeature";
import {
    useGetFeatureByProjectKeyAndFeatureKeyQuery,
    useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery,
    useGetProjectByKeyQuery,
    useUpdateBookmarkMutation,
} from "../../api/featureflowApi";
import {useFeatureflow} from 'react-featureflow-client'
import {isAllowed} from "../../utils/authentication";
import {updateFeatureModal} from "../../modals/FeatureFormModal";
import console from "../../utils/debug";
import {withRouter} from 'react-router';
import {goToFeaturesRoute, goToRoute} from "../../utils/routing";
import UpgradeLink from "../../components/UpgradeLink";
import Audit from "../Admin/Audit";
import {updateRulesModal} from "../../modals/RulesFormModal";
import {FeatureSettingsTab} from "./FeatureSettingsTab";
import {FeatureTargetingTab} from "./FeatureTargetingTab";
import type {FeatureControl} from "../../api/feature";

export type Props = {
    environment: Environment,
    featureKey: string,
    project: Project,
    updateBookmark: (featureId: string, bookmarked: boolean) => void,
};

const FeatureDetailPage = (props: any): React.Node => {
    const [currentTab, setCurrentTab] = useState(props.params.featureDetailPageTab || 'overview');
    const {projectKey, environmentKey, featureKey} = props.params;
    const {data: project} = useGetProjectByKeyQuery(projectKey);
    const {data: environment} = useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery({projectKey, environmentKey});
    const {data: feature} = useGetFeatureByProjectKeyAndFeatureKeyQuery({projectKey, featureKey});
    const featureControl: FeatureControl = feature && environment ? feature.controls[environment.key] : undefined;
    const [updateBookmark, {
        isSuccess: updateBookmarkIsSuccess,
        isError: updateBookmarkIsError,
        error: updateBookmarkError
    }] = useUpdateBookmarkMutation();

    const limits = useSelector((state => state.subscriptionOutline.limits));
    const copyFeatureKeyTooltip = feature ? <Tooltip id={feature.id + 'copy'}>Copy '{feature.key}' to clipboard</Tooltip> : undefined;
    const editFeatureTooltip = feature ? <Tooltip id={feature.id + 'edit'}>Edit {feature.key}</Tooltip> : undefined;

    const lastImpressionDate = access(featureControl, '.statistics.lastImpressionDate') !== null ?
        moment(access(featureControl, '.statistics.lastImpressionDate')).format('Do MMM YYYY, h:mma')
        : 'None';

    const doToggleBookmark = (featureId: string, bookmarked: boolean): void => {
        updateBookmark({featureId, bookmarked});
    };
    useEffect(() => {
        if(updateBookmarkIsSuccess){
            Alert.success(`Bookmark saved.`);
        }
    }, [updateBookmarkIsSuccess]);

    useEffect(() => {
        if(updateBookmarkIsError){
            Alert.warning(`We encountered an error, the bookmark was not saved. ${updateBookmarkError.error || ''}`);
        }
    }, [updateBookmarkIsError]);

    const handleTabSelect = (tabKey: string) => {
        setCurrentTab(tabKey);
        if(tabKey){
            goToRoute(`${featuresUrl}/${featureKey}/${tabKey}`);
        }
    }

    const featuresUrl = `/projects/${projectKey}/environments/${environmentKey}/features`
    const getFeatureDetailUrl = (projectKey, environmentKey, featureKey) => {
        return `/projects/${projectKey}/environments/${environmentKey}/features/${featureKey}`
    }
    return <>
            {feature && environment && project &&
            <>
            <Row>
                <Col xs={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item active>
                            <Button className="btn-link" style={{paddingLeft: 0, paddingRight: 0}}>{project.name}</Button>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <DropdownButton
                                style={{paddingLeft: 0, paddingRight: 0}}
                                className="btn-link"
                                bsSize="medium"
                                title={environment.name}
                                id="dropdown-size-extra-small"
                            >
                                {project && project.environments && project.environments.map(environment =>
                                    <LinkContainer to={{pathname: getFeatureDetailUrl(projectKey, environment.key, featureKey)}}>
                                        <MenuItem eventKey={environment.id}>{environment.name}({environment.key})</MenuItem>
                                    </LinkContainer>
                                )}
                            </DropdownButton>
                        </Breadcrumb.Item>
                        <LinkContainer to={{pathname: `${featuresUrl}`}}>
                            <Breadcrumb.Item href={featuresUrl}>
                                <Button className="btn-link" style={{paddingLeft: 0, paddingRight: 0}}>Features</Button>
                            </Breadcrumb.Item>
                        </LinkContainer>
                        <Breadcrumb.Item active>
                            <Button className="btn-link" style={{paddingLeft: 0, paddingRight: 0}}>{feature.name}</Button>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={8}>
                    <FeatureStatusIndicator featureKey={feature.key}
                                            available={featureControl?.available||false}
                                            trafficStatus={featureControl.trafficStatus}
                                            targetingStatus={featureControl.targetingStatus}
                                            staleDays={featureControl.staleDays}
                                            environment={environment.name}
                                            style={{marginRight: 10, paddingTop: 4, float: 'left'}}
                    />
                    <h3 style={{marginTop: 0}}>
                        {feature.name} &nbsp;
                    </h3>

                    <p>
                        <OverlayTrigger
                            overlay={copyFeatureKeyTooltip} placement="bottom"
                            delayShow={300} delayHide={150}>
                            <CopyToClipboard text={feature.key}
                                             onCopy={() => Alert.success(`Copied <b>${feature.key}</b> to clipboard`, {html: true})}>
                                        <span style={{cursor: 'pointer'}}>
                                            <Label bsStyle="default">Key: {feature.key} &nbsp;<FaCopy/></Label>
                                        </span>
                            </CopyToClipboard>
                        </OverlayTrigger>
                    </p>
                    {feature.description &&
                    <p style={{marginTop: 0}}>
                        {feature.description} &nbsp;
                    </p>}
                    {feature.jiraIssues &&
                    <p style={{marginTop: 0}}>
                        {feature.jiraIssues.map(issue => {
                            return <a href={issue.url} target="_blank" rel="noopener noreferrer" style={{marginRight: 5}}
                                      className="label label-primary">{issue.key}</a>
                        })}
                    </p>}
                    {feature.tags &&
                    <p style={{marginTop: 0}}>
                        {feature.tags.map(tag => {
                            return <p style={{marginRight: 5}}
                                      className="label label-success">{tag}</p>
                        })}
                    </p>}
                </Col>

                <Col xs={4} className="text-right" style={{marginTop: 0}}>
                    <Maintainer maintainer={feature.maintainer} featureKey={feature.key} featureName={feature.name}/>
                    <p><span
                        className="text-muted">Created: </span><span>{moment(access(featureControl, '.createdDate')).format('Do MMM YYYY, h:mma')}</span>
                    </p>
                    <p><span
                        className="text-muted">Last updated: </span><span>{moment(access(featureControl, '.lastModifiedDate')).format('Do MMM YYYY, h:mma')}</span>
                    </p>
                    <p><span className="text-muted">Last impression: </span><span>{lastImpressionDate}</span></p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right" lg={12}>
                    <BookmarkFeature
                        featureId={feature.id}
                        bookmarked={feature.bookmarked}
                        onClick={doToggleBookmark}
                    />
                </Col>
            </Row>
            <Tabs defaultActiveKey={currentTab}
                  id="feature-detail-tabs"
                  activeKey={currentTab}
                  onSelect={handleTabSelect}
                  animation={false}>
                <Tab eventKey={'overview'} title="Overview">
                    <FeatureControlDetails environment={environment} feature={feature} featureControl={featureControl}/>
                </Tab>
                <Tab eventKey={'targeting'} title="Targeting">
                     <FeatureTargetingTab environment={environment} feature={feature} featureControl={featureControl} variants={feature.variants} />
                </Tab>
                <Tab eventKey={'settings'} title="Settings">
                    <FeatureSettingsTab feature={feature} featureControl={featureControl} environmentKey={environmentKey} featuresUrl={featuresUrl}/>
                </Tab>
                <Tab eventKey={'audit'} title="Audit">
                    <h2>Audit</h2>
                    { limits.auditLog
                        ? <Audit projectKey={feature.projectKey} featureKey={feature.key}/>
                        : <p> Audit logs are not available with the current plan. <UpgradeLink/></p>}
                </Tab>
            </Tabs>
            </>
            }
        </>
}

export default withRouter(FeatureDetailPage);